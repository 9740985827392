<template>
  <el-dialog :visible.sync="dialogVisible" :show-close="false" width="50%" :close-on-click-modal="false" @close="handleClose">
    <el-card>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="资源名称" prop="dataName">
              <el-input placeholder="请输入资源名称" v-model="form.dataName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产品类型" prop="productType">
              <el-select v-model="form.productType" @change="changeProductType" placeholder="请选择产品类型">
                <el-option label="公共" value="0"></el-option>
                <el-option v-for="(item,index) in productTypeList" :key="index" :label="item.dictionaryName" :value="item.dictionaryCode"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="产品名称" prop="productId">
              <el-select v-model="form.productId" :disabled="showProductName" @change="changeProduct" placeholder="请选择产品名称">
                <el-option v-for="(item,index) in productList" :key="index" :label="item.productName" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="资料类型" prop="dataType">
              <el-select v-model="form.dataType" placeholder="请选择资料类型">
                <el-option label="视频" value="1"></el-option>
                <!-- <el-option label="表单" value="2"></el-option> -->
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="开始时间" prop="beginDate">
              <el-date-picker
                v-model="form.beginDate"
                value-format="yyyy-MM-dd"
                :picker-options="pickerBeginDateOptions"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结束时间" prop="endDate">
              <el-date-picker
                v-model="form.endDate"
                value-format="yyyy-MM-dd"
                :picker-options="pickerEndDateOptions"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="资源上传">
              <el-upload
                :class="videoForm.showVideoPath ? '' : 'avatar-uploader'"
                :action="action"
                v-loading="loading"
                v-bind:on-progress="uploadVideoProcess"
                v-bind:on-success="handleVideoSuccess"
                v-bind:before-upload="beforeUploadVideo"
                v-bind:show-file-list="false"
              >
                <video
                  v-if="videoForm.showVideoPath != '' && !videoFlag"
                  v-bind:src="videoForm.showVideoPath"
                  class="avatar video-avatar"
                  controls="controls"
                >
                  您的浏览器不支持视频播放
                </video>
                <i v-else-if="videoForm.showVideoPath == '' && !videoFlag" class="el-icon-plus avatar-uploader-icon"></i>
                <!-- <el-progress
                  v-if="videoFlag == true"
                  type="circle"
                  v-bind:percentage="videoUploadPercent"
                  style="margin-top: 7px"
                ></el-progress> -->
              </el-upload>
              <el-button>
                <el-upload
                :action="action"
                v-bind:on-progress="uploadVideoProcess"
                v-bind:on-success="handleVideoSuccess"
                v-bind:before-upload="beforeUploadVideo"
                v-bind:show-file-list="false"
              >
              上传视频
              </el-upload>
              </el-button>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="注意事项">
              <el-upload
                :class="imageUrl ? '' : 'avatar-uploader'"
                :action="action"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload1"
                :on-success="handleAvatarSuccess">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <el-button><el-upload
                :action="action"
                :before-upload="beforeAvatarUpload1"
                :on-success="handleAvatarSuccess"
                :file-list="fileList"
              >
              <img v-if="false" :src="imageUrl" class="avatar">
              上传图片</el-upload></el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="视频封面">
              <el-upload
                :class="imageUrl1 ? '' : 'avatar-uploader'"
                :action="action"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload2"
                :on-success="handleAvatarSuccess1">
                <img v-if="imageUrl1" :src="imageUrl1" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <el-button><el-upload
                :action="action"
                :before-upload="beforeAvatarUpload2"
                :on-success="handleAvatarSuccess1"
                :file-list="fileList"
              >
              <img v-if="false" :src="imageUrl1" class="avatar">
              上传图片</el-upload></el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-col :span="24">
          <el-form-item label="注意事项">
            <el-input type="textarea" v-model="form.remark"></el-input>
          </el-form-item>
        </el-col> -->
      </el-form>
    </el-card>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="sureCommit('form')">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { postRequest, postRequestAnthor } from '@/api'
import {imgaction} from 'src/utils/cities'
export default {
  data() {
    return {
      dialogVisible: true,
      imageUrl: '',
      imageUrl1: '',
      showProductName: false,
      videoFlag: false,
      //是否显示进度条
      videoUploadPercent: '',
      //进度条的进度，
      isShowUploadVideo: false,
      //显示上传按钮
      videoForm: {
        showVideoPath: '',
      },
      form: {
        dataName: '',
        productType: '',
        dataType: '',
        beginDate: '',
        endDate: '',
        remark: '',
        createBy: '2',
        fileName: '',
        picture: '',
        videoCover: ''
      },
      rules:{
        dataName: [
          {required: true, message: '请输入资源名称', trigger: 'blur'}
        ],
        productType: [
          {required: true, message: '请选择产品类型', trigger: 'change'}
        ],
        productId: [
          {required: false, message: '请选择产品名称', trigger: 'input'}
        ],
        dataType: [
          {required: true, message: '请选择资料类型', trigger: 'change'}
        ],
        beginDate: [
          {required: true, message: '请选择开始时间', trigger: 'change'}
        ],
        endDate: [
          {required: false, message: '请选择结束时间', trigger: 'change'}
        ],
      },
      action: imgaction,
      productTypeList: [],
      productList: [],
      imageUrl: '',
      loading: false,
      fileList:[],
      pickerBeginDateOptions: {
        disabledDate: (time) => {
          return time.getTime() < new Date() - 86400000;
        },
      },
      pickerEndDateOptions: {
        disabledDate: (time) => {
          return time.getTime() < new Date(this.form.beginDate);
        },
      },
    }
  },
  props: ['info'],
  mounted(){
    if (this.info) {
      postRequestAnthor('/videoform/queryvideoforminfo', {id: this.info.id}).then(res=>{
        res.value1 = [res.beginDate, res.endDate]
        this.imageUrl = res.picture
        this.imageUrl1 = res.videoCover
        if (res.productId == '' || res.productId == null) {
          res.productId = ''
        } else {
          res.productId = Number(res.productId)
        }
        this.form = res
        this.videoForm.showVideoPath = res.fileName
        
      })
      postRequest('/product/queryProduct/', {catalogCode: this.info.productType}).then(res=>{
        console.log(res)
        this.productList = res
      })
    }
    postRequestAnthor('/dict/getDictByCategory',{dictionaryCategoryCode: '04'}).then(res=>{
      console.log(res, '会籍、单品')
      this.productTypeList = res
    })
    
  },
  methods: {
    handleClose() {
      this.$emit('closeAddFormOrVideo')
    },
    // changeDate123(val){
    //   console.log(123)
    //   this.$forceUpdate()
    //   console.log(val)
    //   this.form.beginDate = val[0]
    //   this.form.endDate = val[1]
    // },
    beforeAvatarUpload1(file) {
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error('上传注意事项图片大小不能超过 5MB!');
      }
      return isLt2M;
    },
    beforeAvatarUpload2(file) {
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error('上传注意事项图片大小不能超过 5MB!');
      }
      return isLt2M;
    },
    handleAvatarSuccess(res, file) {
      console.log(res, 'res', file, 'file')
      if (res.code == 200) {
        this.$message({
          message: '图片上传成功',
          type: 'success'
        })
        this.form.picture = res.data
        this.imageUrl = URL.createObjectURL(file.raw);
      }
    },
    handleAvatarSuccess1(res, file) {
      console.log(res, 'res', file, 'file')
      if (res.code == 200) {
        this.$message({
          message: '图片上传成功',
          type: 'success'
        })
        this.form.videoCover = res.data
        this.imageUrl1 = URL.createObjectURL(file.raw);
      }
    },
    changeProductType(val){
      console.log(val)
      if (val == 0) {
        this.showProductName = true
      } else {
        this.showProductName = false
      }
      this.form.productId = ''
      postRequest('/product/queryProduct',{catalogCode: val}).then(res=>{
        console.log(res)
        this.productList = res
      })
    },
    changeProduct(){
      this.$forceUpdate()
    },
    sureCommit(form) {
      console.log(this.form.fileName == '')
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.loading || this.form.fileName == '') {
            this.$message({
              message: '请先完成视频上传',
              type: 'warning'
            })
          } else {
            if (this.form.dataName == '' || this.form.productType == '' || this.form.dataType == '' || this.imageUrl == '' || this.form.value1 == '') {
              this.$message({
                message: '请完善信息',
                type: 'warning'
              })
            } else {
              if (this.info != '') {
                postRequestAnthor('/videoform/update',this.form).then(res=>{
                  console.log(res, '修改')
                  this.$message({
                    message: '修改成功',
                    type: 'success'
                  })
                  this.handleClose()
                })
              } else {
                postRequestAnthor('/videoform/addsave',this.form).then(res=>{
                  console.log(res, '添加')
                  this.$message({
                    message: '添加成功',
                    type: 'success'
                  })
                  this.handleClose()
                })
              }
            }
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
      
    },
    beforeUploadVideo(file) {
        if (['video/mp4'].indexOf(file.type) == -1) {
            this.$message({
              message: "请上传mp4格式视频",
              type: 'warning'
            });
            return false;
        }
        this.isShowUploadVideo = false;
    },
    //进度条
    uploadVideoProcess(event, file, fileList) {
      this.videoFlag = true;
      this.videoUploadPercent = file.percentage.toFixed(0) * 1;
      this.loading =true
    },
    //上传成功回调
    handleVideoSuccess(res, file) {
      console.log(res, 'res')
      this.videoForm.showVideoPath = res.data
      this.form.fileName = res.data
      this.isShowUploadVideo = true;
      this.videoFlag = false;
      this.videoUploadPercent = 0;
      this.loading = false
      //前台上传地址
      //if (file.status == 'success' ) {
      //    this.videoForm.showVideoPath = file.url;
      //} else {
      //     this.$message("上传失败，请重新上传");
      //}

      //后台上传地址
      if (res.Code == 0) {
          this.videoForm.showVideoPath = res.data;
      } else {
          this.$message({
            message: '视频上传成功',
            type: 'success'
          });
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
.el-range-editor {
  width: 100%;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #409EFF;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.avatar[data-v-6df196da] {
  width: 100%;
  height: 100%;
}
.el-date-editor {
  width: 100%;
}
.avatar-uploader {
  width: 178px !important;
  height: 178px !important;
  border: 1px dashed #409EFF;
  border-radius: 5px;
  color: #409EFF;
}
.el-loading-mask {
  width: 178px !important;
  height: 178px !important;
}
.el-card {
  margin-top: -30px;
}
</style>