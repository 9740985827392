<template>
  <div class="organ-management-page">
    <el-row>
      <el-col :span="24">
        <el-card shadow="always">
          <el-form :inline="true" :model="form" class="demo-form-inline1 search searchFrom" size="small">
                <el-form-item label="资源名称:">
                  <el-input
                    v-model="form.name"
                    :clearable="true"
                    placeholder="请输入资讯名称"
                  ></el-input>
                </el-form-item>
                <el-form-item label="状态:">
                  <el-select v-model="form.region" placeholder="请选择状态">
                    <el-option label="全部 " value=""></el-option>
                    <el-option label="有效" value="0"></el-option>
                    <el-option label="无效" value="1"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="开始时间:">
                  <el-date-picker
                    v-model="form.value1"
                    value-format="yyyy-MM-dd" format="yyyy-MM-dd"
                    :picker-options="pickerOptionsStart"
                    type="date"
                    placeholder="选择开始时间">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间:">
                  <el-date-picker
                    v-model="form.value2"
                    value-format="yyyy-MM-dd" format="yyyy-MM-dd"
                    :picker-options="pickerOptionsEnd"
                    type="date"
                    placeholder="选择结束时间">
                  </el-date-picker>
                </el-form-item>
                <el-form-item>
              <el-button icon="el-icon-search" type="primary" :disabled="disabledSearch" @click="getAllFormAndVideo(1)">查询</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <el-card  shadow="always" class="card">
      <div>
        <el-button plain icon="el-icon-plus" type="primary" @click="addNew('')">添加</el-button>
        <el-button plain icon="el-icon-delete" type="danger" @click="handleDelete">删除</el-button>
      </div>
      <div class="table-block">
        <div class="Selectnum">已选中 <span style="color:#5479FF">{{multipleSelection.length}}</span> 条信息</div>
        <el-table :data="tableData" v-loading="loading" @row-click="handleClickTableRow" :header-cell-style="{'text-align': 'center','background': '#EEF1FC', 'color': '#999'}" :cell-style="{'text-align':'center'}" ref="multipleTable" style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" fixed width="50"></el-table-column>
          <el-table-column prop="dataName" label="资源名称"> </el-table-column>
          <el-table-column prop="beginDate" label="开始时间"> </el-table-column>
          <el-table-column prop="endDate" label="结束时间"> </el-table-column>
          <el-table-column prop="productTypeName" label="产品类别"> </el-table-column>
          <el-table-column prop="createTime" label="添加时间"> </el-table-column>
          <el-table-column prop="state" width="80" label="状态">
            <template slot-scope="scope">
               <el-switch
                v-model="scope.row.status"
                active-color="#5479FF"
                inactive-color="#D5D5D5"
                active-value="0"
                @change="changeStatus(scope.row)"
                inactive-value="1">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" width="80">
            <template slot-scope="scope">
              <el-button @click="addNew(scope.row)" class="updatebutton" type="text" size="small">修改</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background>
      </el-pagination>
    </el-card>
    
    <addFormOrVideo :info="info" v-if="showNewCopyRelease" @closeAddFormOrVideo="closeAddFormOrVideo"></addFormOrVideo>
  </div>
</template>

<script>
import addFormOrVideo from '@/views/infomationManagement/components/addFormOrVideo'
import { postRequestAnthor } from '@/api'
import { formatTime } from '@/utils/utils'
export default {
  data () {
    return {
      form: {
        name: '',
        region: '',
        value1: '',
        value2: '',
        area: ''
      },
      tableData: [],
      multipleSelection: [],
      showNewCopyRelease: false,
      total: null,
      loading: true,
      disabledSearch: false,
      pageNo: 1,
      pageSize: 10,
      pickerOptionsStart: {
                     disabledDate: time => {
                            if (this.form.value2) {
                                 return time.getTime() > new Date(this.form.value2).getTime()
                           }
                    }
             },
             pickerOptionsEnd: {
                      disabledDate: time => {
                            if (this.form.value1) {
                                    return time.getTime() < new Date(this.form.value1).getTime() - 86400000
                            }
                       }
             },
    }
  },
  components: { addFormOrVideo },
  mounted(){
    this.getAllFormAndVideo()
  },
  methods: {
    addNew (val) {
      this.info = val
      console.log(123)
      this.showNewCopyRelease = true
    },
    changeStatus(val){
      let data = {
        id: val.id,
        status: val.status
      }
      postRequestAnthor('/videoform/update', data).then(res=>{
        console.log(res)
        this.$message({
          message: '状态修改成功',
          type: 'success'
        })
        // this.getAllFormAndVideo()
      })
    },
    handleDelete(){
      if (this.multipleSelection.length <= 0) {
        this.$message({
          message: '请选择删除项',
          type: 'success'
        })
      } else {
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          let data = {
            userId: '2',
            ids: []
          }
          this.multipleSelection.map(item=>{
            data.ids.push(item.id)
          })
          postRequestAnthor('/videoform/deletes', data).then(res=>{
            console.log(res, '批量删除')
            this.$message({
              message: "删除成功",
              type: 'success'
            })
            this.getAllFormAndVideo()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
    },
    handleSelectionChange (val) {
      console.log(val)
      this.multipleSelection = val
    },
    closeAddFormOrVideo () {
      this.showNewCopyRelease = false
      this.getAllFormAndVideo()
    },
    handleClickTableRow(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.pageSize = val
        this.getAllFormAndVideo()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.pageNo = val
        this.getAllFormAndVideo()
      },
    getAllFormAndVideo(no){
      this.disabledSearch = true
      this.loading = true
      let data = {
        pageNo: no ? no : this.pageNo,
        pageSize: this.pageSize,
        dataName: this.form.name ? this.form.name : '',
        status: this.form.region ? this.form.region : '',
        beginDate: this.form.value1 ? formatTime(this.form.value1) : '',
        endDate: this.form.value2 ? formatTime(this.form.value2) : '',
      }
      postRequestAnthor('/videoform/queryvideoformlist',data).then(res=>{
        this.loading = false
        this.disabledSearch = false
        console.log(res, '视频表单列表信息')
        this.total = res.count
        res.data.map(item=>{
          if (item.status == '0') {
            item.state = '有效'
          } else {
            item.state = '无效'
          }
        })
        this.tableData = res.data
      }).catch(()=>{
        this.loading = false
        this.disabledSearch = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.search > .el-form-item {
  margin-right: 2px !important;
}
.organ-management-page {
  background: transparent;
}
#rich-editor {
  width: 100%;
  height: 200px;
  background: #fff;
  border: 1px solid #999;
}
.el-form-item{
  margin-right: 4px !important;
}
.Selectnum{
  padding:6px 30px;
  border:1px solid #BFCBF4;
  font-size: 12px;
  color:#666;
  border-radius: 5px;
  margin-bottom:10px;
}
</style>
